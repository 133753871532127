<template>
  <el-dialog
    v-model="showAuxiliaryCodeTypeDialog"
    width="30%"
    align-center
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    class="elv-auxiliary-code-type-dialog"
    @close="onCloseDialog"
  >
    <template #header="{ titleId }">
      <h4 :id="titleId" class="elv-auxiliary-code-type-dialog-header__title">
        {{ props.model === 'add' ? t('title.addAuxiliaryCode') : t('title.editAuxiliaryCode') }}
      </h4>
    </template>
    <el-scrollbar v-loading="dialogLoading" class="elv-auxiliary-code-type-content" max-height="calc(100vh - 193px)">
      <el-form ref="formRef" :model="auxiliaryCodeTypeForm" label-position="top" :rules="rules">
        <el-form-item
          :label="t('title.auxiliaryCodeName')"
          prop="name"
          class="elv-auxiliary-code-type-form-item__base-name"
        >
          <el-input v-model="auxiliaryCodeTypeForm.name" placeholder=" " />
        </el-form-item>
        <el-form-item :label="t('title.auxiliaryCodeType')" prop="type">
          <el-select v-model="auxiliaryCodeTypeForm.type" :disabled="props.model === 'edit'">
            <el-option
              v-for="item in auxiliaryCodeOptions"
              :key="item.value"
              :label="transformI18n(item.label)"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item v-if="auxiliaryCodeTypeForm.type === 'ITEM'" :label="t('common.options')" prop="type">
          <div class="elv-auxiliary-code-type-form-item__options">
            <template v-if="auxiliaryCodeTypeForm.auxiliaryItems?.length">
              <div
                v-for="item in auxiliaryCodeTypeForm.auxiliaryItems"
                :key="item.index"
                class="elv-auxiliary-code-type-form-item__options-item"
              >
                <el-input v-model="item.value" maxlength="50" />
                <SvgIcon
                  name="common-close"
                  width="18"
                  height="18"
                  fill="#1753eb"
                  @click="onDeleteOption(item.index)"
                />
              </div>
            </template>
            <div class="elv-auxiliary-code-type-form-item__options-add" @click="onAddOption">
              <SvgIcon name="add-default" width="16" height="16" fill="#1753eb" />{{ t('button.addANewOption') }}
            </div>
          </div>
        </el-form-item>
      </el-form>
    </el-scrollbar>
    <template #footer>
      <elv-button
        height="44"
        width="100"
        round
        type="primary"
        :loading="saveLoading"
        :disabled="dialogLoading || saveDisabled"
        @click="onSaveChartOfAccount"
      >
        {{ t('button.save') }}
      </elv-button>
    </template>
  </el-dialog>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import LedgerApi from '@/api/LedgerApi'
import { ElMessage } from 'element-plus'
// eslint-disable-next-line no-unused-vars
import { $t, transformI18n } from '@/i18n/index'
import { cloneDeep, isEmpty } from 'lodash-es'
import type { FormInstance, FormRules } from 'element-plus'
import { auxiliaryCodeTypeOptions } from '@/config/reports/index'

const props = defineProps({
  currentData: {
    type: Object,
    default: () => {
      return {}
    }
  },
  model: {
    type: String,
    default: 'add' // add, edit
  }
})

const route = useRoute()
const { t } = useI18n()

const emit = defineEmits(['onResetList'])

const showAuxiliaryCodeTypeDialog = ref(false)
const saveLoading = ref(false)
const dialogLoading = ref(false)
const auxiliaryCodeTypeForm: any = ref({
  name: '',
  type: 'COUNTERPARTY',
  auxiliaryItems: []
})
const formRef = useTemplateRef<FormInstance>('formRef')

const rules = reactive<FormRules>({
  name: [
    {
      required: true,
      trigger: 'blur',
      message: 'Auxiliary code name is required'
    }
  ],
  type: [
    {
      required: true,
      trigger: 'blur',
      message: 'Auxiliary code type is required'
    }
  ]
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const auxiliaryCodeOptions = computed(() => {
  return auxiliaryCodeTypeOptions.filter((item) => item.value !== 'ENTITY_ACCOUNT')
})

const saveDisabled = computed(() => {
  if (dialogLoading.value) return true
  if (auxiliaryCodeTypeForm.value.name === '') return true
  if (auxiliaryCodeTypeForm.value.type === 'ITEM') {
    if (!auxiliaryCodeTypeForm.value.auxiliaryItems?.length) return true
    return auxiliaryCodeTypeForm.value.auxiliaryItems.some((item: any) => item.value === '')
  }
  return false
})

const onAddOption = () => {
  auxiliaryCodeTypeForm.value.auxiliaryItems.push({
    index: auxiliaryCodeTypeForm.value.auxiliaryItems.length,
    value: ''
  })
}

const onDeleteOption = (index: number) => {
  auxiliaryCodeTypeForm.value.auxiliaryItems.splice(index, 1)
  auxiliaryCodeTypeForm.value.auxiliaryItems.forEach((item: any, i: number) => {
    item.index = i
  })
}

const onCheckAuxiliaryCodeTypeDialog = () => {
  showAuxiliaryCodeTypeDialog.value = !showAuxiliaryCodeTypeDialog.value
}

const onCreateAuxiliaryCodeType = async () => {
  try {
    const params = cloneDeep(auxiliaryCodeTypeForm.value)
    if (params.type === 'ITEM') {
      params.auxiliaryItems = params.auxiliaryItems.map((item: any) => {
        return { value: item.value }
      })
    } else {
      delete params.auxiliaryItems
    }
    await LedgerApi.createAuxiliaryType(entityId.value, params)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    onCheckAuxiliaryCodeTypeDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onEditAuxiliaryCodeType = async () => {
  try {
    const params = cloneDeep(auxiliaryCodeTypeForm.value)
    if (params.type === 'ITEM') {
      params.auxiliaryItems = params.auxiliaryItems.map((item: any) => {
        const data: any = {
          value: item.value
        }
        if (item.auxiliaryItemId) data.auxiliaryItemId = item.auxiliaryItemId
        return data
      })
    } else {
      delete params.auxiliaryItems
    }
    delete params.type
    await LedgerApi.editAuxiliaryType(entityId.value, props.currentData?.auxiliaryTypeId, params)
    ElMessage.success(t('message.success'))
    emit('onResetList')
    onCheckAuxiliaryCodeTypeDialog()
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    saveLoading.value = false
  }
}

const onSaveChartOfAccount = () => {
  if (!formRef.value) return
  formRef.value?.validate(async (isValidate: boolean) => {
    if (isValidate) {
      saveLoading.value = true
      if (props.model === 'add') {
        onCreateAuxiliaryCodeType()
      } else {
        onEditAuxiliaryCodeType()
      }
    }
  })
}

const onCloseDialog = () => {
  formRef.value?.resetFields()
  auxiliaryCodeTypeForm.value = {
    name: '',
    type: 'COUNTERPARTY',
    auxiliaryItems: []
  }
}

defineExpose({ onCheckAuxiliaryCodeTypeDialog })

watch(
  [() => showAuxiliaryCodeTypeDialog.value, () => props.currentData],
  async () => {
    if (
      showAuxiliaryCodeTypeDialog.value &&
      props.model === 'edit' &&
      !isEmpty(props.currentData) &&
      props.currentData !== null
    ) {
      const auxiliaryCodeTypeData = cloneDeep(props.currentData)
      auxiliaryCodeTypeForm.value = {
        name: auxiliaryCodeTypeData.name,
        type: auxiliaryCodeTypeData.type,
        auxiliaryItems:
          auxiliaryCodeTypeData?.auxiliaryItems?.map((item: any, index: number) => {
            return {
              index,
              auxiliaryItemId: item.auxiliaryItemId,
              value: item.value
            }
          }) || []
      }
      console.log(auxiliaryCodeTypeForm.value)
    }
  },
  {
    immediate: true
  }
)
</script>

<style lang="scss">
.elv-auxiliary-code-type-dialog {
  width: 620px;
  box-shadow:
    0px 2px 6px rgba(0, 0, 0, 0.05),
    0px 0px 1px rgba(0, 0, 0, 0.3);
  border-radius: 6px;

  .el-dialog__header {
    text-align: center;
    font-size: 16px;
    line-height: 24px;
    height: 54px;
    font-family: 'Plus Jakarta Sans';
    font-weight: 700;
    margin: 0;
    padding: 0;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-start;
    color: #0e0f11;
    padding-left: 24px;
    border-bottom: 1px solid #edf0f3;

    .elv-auxiliary-code-type-dialog-header__title {
      font-family: 'Plus Jakarta Sans';
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #0e0f11;
      margin: 0;
    }
  }

  .el-dialog__body {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    .elv-auxiliary-code-type-content {
      width: 100%;

      .el-scrollbar__view {
        padding: 18px 24px 0px;
        box-sizing: border-box;
        border-radius: 4px;
        min-height: 170px;
        width: 100%;
      }
    }

    .el-select .el-select__wrapper.is-disabled {
      .el-select__selected-item {
        color: #0e0f11;
      }
    }

    .elv-auxiliary-code-type-form-item__base {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .el-form-item {
        .el-input__wrapper {
          width: 150px;
        }

        &:first-of-type,
        &:last-of-type {
          .el-input__wrapper,
          .el-select__wrapper {
            width: 164px;
          }
        }
      }
    }
    // .elv-auxiliary-code-type-form-item__id {
    //   .el-input-number {
    //     .el-input__inner {
    //       text-align: left;
    //     }
    //   }
    // }

    .elv-auxiliary-code-type-form-item__line {
      color: #838d95;
      font-family: 'Plus Jakarta Sans';
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      margin-bottom: 16px;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        width: 583px;
        height: 1px;
        background: #edf0f3;
        bottom: 50%;
        right: 0;
        transform: translateY(50%);
      }

      &.entryLines::after {
        width: 583px;
      }
    }

    .elv-auxiliary-code-type-form-item__entry-list {
      width: 100%;
      box-sizing: border-box;
      border-radius: 5px;
      background: #f9fafb;
      display: flex;
      padding: 8px;
      flex-direction: column;
      align-items: flex-start;
      align-self: stretch;
      min-height: 40px;
    }

    .elv-auxiliary-code-type-form-item__entry-title {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      gap: 8px;

      span {
        color: #636b75;
        font-family: 'Plus Jakarta Sans';
        font-size: 13px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        display: block;
        width: 216px;

        &:first-of-type {
          width: 100px;
        }
      }
    }

    .elv-auxiliary-code-type-form-item__entry-item {
      display: flex;
      align-items: center;
      gap: 8px;
      width: 100%;
      margin-top: 8px;

      .el-form-item {
        margin-bottom: 0px !important;
      }

      .elv-auxiliary-code-type-form-item__entry-item__balance {
        display: flex;
        height: 44px;
        width: 100px;
        box-sizing: border-box;
        flex-direction: column;
        background: #f9fafb;
        justify-content: center;
        align-items: flex-start;
        border-radius: 4px;
        border: 1px solid #dde1e6;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.08);
        padding: 8px 12px;
        color: #0e0f11;
        font-family: 'Plus Jakarta Sans';
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        cursor: not-allowed;
      }

      .elv-auxiliary-code-type-form-item__entry-item__type {
        .el-select__wrapper {
          width: 216px;
        }
      }

      .elv-auxiliary-code-type-form-item__entry-item__percent {
        position: relative;

        .el-input__wrapper {
          width: 80px;
          padding: 0 12px;

          .el-input__inner {
            width: 30px;
            text-align: left;
          }
        }

        .el-input-number {
          width: 80px;
        }

        .symbol {
          padding-right: 12px;
          padding-left: 0px;
          color: #dde1e6;
          text-align: right;
          font-family: 'Barlow';
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 28px;
          height: 28px;
          position: absolute;
          right: 1px;
          bottom: 10px;
        }
      }
    }

    .elv-auxiliary-code-type-checking {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #1753eb;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      img {
        display: block;
        width: 16px;
        height: 16px;
        margin-right: 4px;
        animation: loading-rotate 2s linear infinite;
      }
    }

    .elv-auxiliary-code-type-info {
      width: 100%;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      color: #636b75;
      font-family: 'Plus Jakarta Sans';
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;

      svg {
        margin-right: 4px;
      }
    }
  }

  .el-form.el-form--default {
    .el-form-item {
      margin-bottom: 16px;

      &:last-of-type {
        margin-bottom: 0px;
      }

      &.is-error {
        .el-input__wrapper {
          background: #faeee6;
          border: 1px solid #7e4a15;
        }

        .el-input__inner {
          color: #7e4a15;
        }

        .el-form-item__error {
          font-family: 'Plus Jakarta Sans';
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 14px;
          color: #7e4a15;
        }
      }
    }

    .el-form-item__label {
      font-family: 'Plus Jakarta Sans';
      font-weight: 600;
      font-size: 13px;
      line-height: 16px;
      color: #636b75;
      margin-bottom: 8px;

      &::before {
        // 隐藏掉必填的小红星
        display: none;
      }
    }

    .elv-auxiliary-code-type-form-item__options {
      display: flex;
      width: 100%;
      box-sizing: border-box;
      padding: 12px;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      border-radius: 4px;
      background: #f9fafb;

      .elv-auxiliary-code-type-form-item__options-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        box-sizing: border-box;

        .el-input {
          width: fit-content;
        }

        .el-input__wrapper {
          width: 518px;
        }

        svg {
          fill: #838d95;
          cursor: pointer;

          &:hover {
            fill: #1343bf;
          }
        }
      }

      .elv-auxiliary-code-type-form-item__options-add {
        display: flex;
        padding: 6px 8px;
        align-items: center;
        gap: 2px;
        color: #1753eb;
        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
    }

    .el-input__wrapper {
      width: 432px;
      box-sizing: border-box;
    }

    .el-input {
      height: 44px;
      border-radius: 4px;

      &.is-disabled {
        background: #f9fafb;
      }
    }

    .el-input__inner {
      font-family: 'Plus Jakarta Sans';
      font-weight: 400;
      font-size: 14px;
      color: #0e0f11;
    }

    .el-input:not(.is-disabled) .el-input__wrapper {
      &:hover {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      &.is_focus {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }
    }

    .el-select {
      &:hover:not(.el-select--disabled) .el-input__wrapper,
      .el-input.is-focus:not(.el-select--disabled) .el-input__wrapper {
        border: 1px solid #7596eb !important;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
      }

      .el-select__selected-item {
        .el-tag {
          padding: 0 16px;
          height: 24px;
          border-radius: 40px;
          border: 1px solid #d0d4d9;
          box-sizing: border-box;
          color: #636b75;
          background-color: #fff;
          leading-trim: both;
          text-edge: cap;
          font-family: 'Plus Jakarta Sans';
          font-size: 11px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
        }
      }

      .el-select__selection {
        .el-tag {
          display: flex;
          padding: 0px 8px;
          box-sizing: border-box;
          justify-content: center;
          align-items: center;
          gap: 2px;
          border-radius: 3px;
          border: 1px solid #dde1e6;
          background: #edf0f3;

          .el-tag__content {
            color: #1753eb;
            font-family: 'Barlow';
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
          }

          .el-icon.el-tag__close {
            color: #aaafb6;

            &:hover {
              color: #fff;
              background-color: #1753eb;
            }
          }
        }
      }
    }

    .el-input__wrapper.is-focus {
      border: 1px solid #7596eb !important;
      box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.15) !important;
    }
  }

  .el-dialog__footer {
    padding: 20px 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.elv-journal-drawer-filter-tips.el-popper {
  display: flex;
  gap: 6px;
  max-width: 400px;
  max-height: 200px;
  flex-wrap: wrap;
}
</style>
